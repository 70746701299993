import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import { Notifications } from 'react-push-notification'
import { ToastContainer } from 'react-toastify'
import { AuthContext } from '../contexts/AuthContext'

const Layout = () => {
  const { auth } = useContext(AuthContext)
  const navigate = useNavigate()

  // Checking if user is already logged in
  useEffect(() => {
    if (auth === false) {
      navigate('/auth/login')
    }
  }, [auth])

  return (
    <div className="h-screen flex flex-col">
      <Notifications />
      <ToastContainer />
      <Navbar />
      <Outlet />
    </div>
  )
}

export default Layout
