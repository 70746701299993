// Socket.io
import { io } from 'socket.io-client'
import { env } from '../config'

// Setting socket.io-client
function Socket(callback) {
  const socket = io(env.SOCKET_URI)
  socket.on('connect', () => {
    // Listen for new connections
    callback(socket)
  })
}

export default Socket
