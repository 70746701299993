import React from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContactContext } from '../../contexts/ContactContext'
import { sendRequest } from '../../hooks/API'
import { generateAvatar } from '../../hooks/Avatar'
import { formatDate } from '../../hooks/Date'
import Toast from '../../hooks/Toast'
import { isArabic, lang } from '../../language'
import Button from '../Base/Button'

const ContactItem = ({
  convId,
  name,
  waid,
  createdAt,
  lastActivity,
  agent,
  agents,
}) => {
  const navigate = useNavigate()
  const { contacts, setContacts } = useContext(ContactContext)
  createdAt = formatDate(createdAt)
  lastActivity = formatDate(lastActivity)

  // Updating agent
  const updateAgent = async (e) => {
    e.preventDefault()

    const id = e.target.value

    // Sending request to server
    const resp = await sendRequest(`/contacts/${convId}`, 'PUT', {
      data: { data: { agent_id: id } },
    })
    if (resp.statusText !== 'OK') return Toast('Something went wrong.', 'error')
    // Checking if there is any error
    if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
    // Showing success messages
    Toast(`Agent successfully changed for ${name}`, 'success')
  }

  // Deleting contact
  const deleteContact = async (e) => {
    e.preventDefault()

    const confirm = window.confirm(
      'Are you sure you want to delete the contact?'
    )
    if (confirm) {
      // Sending request to server
      const resp = await sendRequest(`/contacts/${waid}`, 'DELETE')
      if (resp.statusText !== 'OK')
        return Toast('Something went wrong.', 'error')
      // Checking if there is any error
      if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
      // Removing current one from array
      setContacts(contacts.filter((contact) => contact.wa_id !== waid))
      // Showing success messages
      Toast(`Account deleted for ${name}`, 'success')
      // Redirecting back to contacts page
      return navigate('/contacts')
    }
  }

  return (
    <div className="mb-2 bg-white shadow lg:w-full w-max flex items-center table-fixed px-4 py-3 rounded-lg hover:bg-gray-50 transition duration-100">
      <div className="lg:w-1/5 min-w-[250px] w-[250px] item flex items-center gap-3">
        <div
          className={`avatar ${
            !isArabic(name) ? 'font-sans' : 'font-noto-sans-arabic'
          }`}
        >
          {generateAvatar(name)}
        </div>
        <div
          className={`font-medium text-gray-800 ${
            !isArabic(name) ? 'font-sans' : 'font-noto-sans-arabic'
          }`}
        >
          {name}
        </div>
      </div>
      <div className="lg:w-1/5 min-w-[200px] w-[200px] item">
        <div className="font-medium text-gray-800 font-sans">{waid}</div>
      </div>
      <div className="lg:w-1/6 min-w-[200px] w-[200px] item">
        <select
          onChange={updateAgent}
          className={`bg-gray-200 rounded ${
            !isArabic(agent) ? 'font-sans' : 'font-noto-sans-arabic'
          }`}
          defaultValue={agent}
        >
          {agents.map((item, index) => (
            <option key={index} value={item.id}>
              {item.profile_name}
            </option>
          ))}
        </select>
      </div>
      <div className="lg:w-1/5 min-w-[150px] w-[150px] item">
        <div className="text-gray-600 font-sans">{createdAt}</div>
      </div>
      <div className="lg:w-1/5 min-w-[216px] w-[216px] item flex gap-16 items-center">
        <div className="text-gray-600 font-sans">{lastActivity}</div>
        <Button
          onClick={deleteContact}
          theme="danger"
          size="sm"
          text={lang('delete')}
        />
      </div>
    </div>
  )
}

export default ContactItem
