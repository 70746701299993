import React, { useEffect, useState } from 'react'
import Container from '../components/Base/Container'
import Card from '../components/Base/Card'
import ContactItem from '../components/Contacts/ContactItem'
import { sendRequest } from '../hooks/API'
import Toast from '../hooks/Toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../components/Base/Loading'
import { ContactContext } from '../contexts/ContactContext'
import { lang } from '../language'

const Contacts = () => {
  const [contacts, setContacts] = useState(null)
  const [information, setInformation] = useState(null)
  const [agents, setAgents] = useState([])

  const fetchAgents = async () => {
    // Fetching all contacts
    const resp = await sendRequest(`/users?limit=99999`)
    // Handling error
    if (resp.statusText !== 'OK') {
      return Toast('Something went wrong.', 'error')
    }
    if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
    setAgents(
      resp.data.data.filter((item) => {
        return item.role !== 'admin'
      })
    )
  }

  const fetchContacts = async (defaultLoad = true) => {
    let endpoint = `/contacts`
    if (!defaultLoad) {
      endpoint += `?page=${information.page + 1}`
    }
    // Fetching all contacts
    const resp = await sendRequest(endpoint)
    // Handling error
    if (resp.statusText !== 'OK') {
      return Toast('Something went wrong.', 'error')
    }
    if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
    if (!defaultLoad) {
      const totalContacts = contacts.concat(resp.data.data)
      setContacts(totalContacts)
    } else {
      setContacts(resp.data.data)
    }
    setInformation(resp.data.information)
  }

  useEffect(() => {
    fetchContacts()
    fetchAgents()
  }, [])

  return (
    <Container>
      <Card.Wrapper>
        <Card.Header>
          <div>
            <h1 className="page-heading">{lang('manage-contacts')}</h1>
            <p className="page-description">{lang('contacts-description')}</p>
          </div>
        </Card.Header>
        {/* Content */}
        <ContactContext.Provider value={{ contacts, setContacts }}>
          {contacts !== null && (
            <div>
              <InfiniteScroll
                dataLength={contacts.length}
                next={() => fetchContacts(false)}
                hasMore={information.page !== information.total_pages}
                loader={<Loading />}
                scrollableTarget="conversations"
              >
                <div className="bg-white shadow lg:w-full w-max flex items-center flex-wrap table-fixed px-4 py-3 rounded-lg mb-2">
                  <div className="lg:w-1/5 min-w-[250px] w-[250px] item text-gray-800 font-medium">
                    {lang('full-name')}
                  </div>
                  <div className="lg:w-1/5 min-w-[200px] w-[200px] item text-gray-800 font-medium">
                    {lang('whatsapp-number')}
                  </div>
                  <div className="lg:w-1/6 min-w-[150px] w-[150px] item text-gray-800 font-medium">
                    {lang('agent')}
                  </div>
                  <div className="lg:w-1/5 min-w-[150px] w-[150px] item text-gray-800 font-medium">
                    {lang('last-activity')}
                  </div>
                  <div className="lg:w-1/5 min-w-[216px] w-[216px] item text-gray-800 font-medium">
                    {lang('created-at')}
                  </div>
                </div>
                {contacts.map((user, index) => (
                  <ContactItem
                    key={index}
                    convId={user.id}
                    name={user.profile_name}
                    waid={user.wa_id}
                    createdAt={user.created_at}
                    lastActivity={user.last_activity}
                    agent={user.agent_id}
                    agents={agents}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </ContactContext.Provider>
      </Card.Wrapper>
    </Container>
  )
}

export default Contacts
