import React, { useContext, useEffect, useState } from 'react'
import { InboxContext } from '../../contexts/InboxContext'
import ChatItem from './ChatItem'
// Infinite Scroll
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../Base/Loading'
import Input from '../Base/Input'
import { useParams } from 'react-router-dom'
import { lang } from '../../language'

const Sidebar = () => {
  const { conversations, methods, information } = useContext(InboxContext)
  const [filteredConv, setFilteredConv] = useState(conversations?.data)
  const { chatId } = useParams()

  useEffect(() => {
    setFilteredConv(conversations?.data)
  }, [conversations])

  if (filteredConv === null || filteredConv === undefined) return null

  // Search for conversations
  const search = (e) => {
    const query = e.target.value.toLowerCase()
    if (query !== '')
      setFilteredConv(
        conversations?.data?.filter((item) => {
          return item.profile_name.toLowerCase().indexOf(query) !== -1
        })
      )
    else {
      setFilteredConv(conversations?.data)
    }
  }

  return (
    <div
      className={`bg-white border-r overflow-auto h-full ${
        chatId !== undefined && 'lg:block hidden'
      }`}
      id="conversations"
    >
      <div className="px-2 py-[0.43rem] border-b">
        <Input
          theme="light"
          placeholder={lang('search-anything')}
          id="search-conv"
          onKeyUp={search}
        />
      </div>
      <ul>
        <InfiniteScroll
          dataLength={filteredConv?.length}
          next={() => methods.fetchConversations(false)}
          hasMore={information.currentPage !== information.totalPages}
          loader={<Loading />}
          scrollableTarget="conversations"
        >
          {filteredConv?.map((conversation, key) => (
            <ChatItem
              chatId={conversation.wa_id}
              name={conversation.profile_name}
              lastMessage={conversation.last_message}
              lastActivity={conversation.last_activity}
              unreadMessages={conversation.unread_messages}
              key={key}
            />
          ))}
        </InfiniteScroll>
      </ul>
    </div>
  )
}

export default Sidebar
