import React from 'react'
import { getLang, lang } from '../../language'

const Loading = () => {
  return (
    <div className="flex justify-center items-center px-4 py-2 font-semibold leading-6 text-sm w-full text-primary-dark bg-primary-dark/[0.1] transition ease-in-out duration-150 mx-auto text-center rounded-lg">
      <svg
        className={`animate-spin ${
          getLang() === 'ar' ? '-mr-1 ml-3' : '-ml-1 mr-3'
        } h-5 w-5 text-primary-dark`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {lang('loading')}...
    </div>
  )
}

export default Loading
