// Languages
export const languages = [
  {
    name: 'Arabic',
    code: 'ar',
  },
  {
    name: 'English',
    code: 'en',
  },
]

// All text
export const text = {
  dashboard: {
    en: 'Dashboard',
    ar: 'لوحة القيادة',
  },
  inbox: {
    en: 'Inbox',
    ar: 'صندوق الوارد',
  },
  contacts: {
    en: 'Contacts',
    ar: 'جهات الاتصال',
  },
  users: {
    en: 'Users',
    ar: 'الموظفين',
  },
  logout: {
    en: 'Logout',
    ar: 'تسجيل خروج',
  },
  'search-anything': {
    en: 'Search for anything...',
    ar: '... بحث',
  },
  today: {
    en: 'Today',
    ar: 'اليوم',
  },
  yesterday: {
    en: 'Yesterday',
    ar: 'البارحة',
  },
  loading: {
    en: 'Loading',
    ar: 'جار التحميل',
  },
  'manage-contacts': {
    en: 'Manage Contacts',
    ar: 'إدارة الاتصال',
  },
  'contacts-description': {
    en: 'Manage all of your Client Contacts here.',
    ar: 'إدارة جميع جهات اتصال العملاء الخاصة بك هنا.',
  },
  'full-name': {
    en: 'Full Name',
    ar: 'الاسم الكامل',
  },
  'whatsapp-number': {
    en: 'WhatsApp Number',
    ar: 'رقم الواتس اب',
  },
  'last-activity': {
    en: 'Last Activity',
    ar: 'النشاط الاخير',
  },
  'created-at': {
    en: 'Created At',
    ar: 'أنشئت في',
  },
  'updated-at': {
    en: 'Updated At',
    ar: 'تم التحديث في',
  },
  delete: {
    en: 'Delete',
    ar: 'حذف',
  },
  name: {
    en: 'Name',
    ar: 'اسم',
  },
  username: {
    en: 'Username',
    ar: 'اسم المستخدم',
  },
  'manage-users': {
    en: 'Manage Users',
    ar: 'ادارة الموظفين',
  },
  'users-description': {
    en: 'Manage all of your Admin and Agents here. Also you could just create one agent or maybe admin. Just try it out!',
    ar: 'قم بإدارة جميع المدراء والموظفين هنا. يمكنك أيضًا إنشاء موظف واحد أو مدير. !',
  },
  'add-user': {
    en: 'Add User',
    ar: 'إضافة مستخدم',
  },
  update: {
    en: 'Update',
    ar: 'تحديث',
  },
  'update-user': {
    en: 'Update User',
    ar: 'تحديث المستخدم',
  },
  'delete-user': {
    en: 'Delete User',
    ar: 'مسح المستخدم',
  },
  'leave-password': {
    en: "Leave the password empty, if you don't want to change it",
    ar: 'اترك كلمة المرور فارغة ، إذا كنت لا تريد تغييرها',
  },
  'email-address': {
    en: 'Email Address',
    ar: 'عنوان البريد الالكترونى',
  },
  password: {
    en: 'Password',
    ar: 'كلمه السر',
  },
  role: {
    en: 'Role',
    ar: 'وظيفة',
  },
  admin: {
    en: 'Admin',
    ar: 'مشرف',
  },
  agent: {
    en: 'Agent',
    ar: 'موظف',
  },
  'create-user': {
    en: 'Create User',
    ar: 'إنشاء مستخدم',
  },
  'type-reply': {
    en: "Type your message or press '/' key for quick replies",
    ar: "اكتب رسالتك أو اضغط على مفتاح '/' للرد السريع",
  },
  send: {
    en: 'Send',
    ar: 'إرسال',
  },
  add: {
    en: 'Add',
    ar: 'يضيف',
  },
  'sign-in-title': {
    en: 'Sign in to your account',
    ar: 'تسجيل الدخول إلى حسابك',
  },
  'remember-me': {
    en: 'Remember me for the 30 days',
    ar: 'تذكرني لمدة ثلاثون يومًا',
  },
  'forgot-password': {
    en: 'If you forgot your password, please contact the administrator',
    ar: 'إذا نسيت كلمة المرور الخاصة بك ، فيرجى الاتصال بعبدالعزيز آل عقيل الغامدي',
  },
  'sign-in': {
    en: 'Sign in',
    ar: 'تسجيل الدخول',
  },
}

// Getting language
export const getLang = () => {
  // Checking local storage if there is language specific
  let selectedLang = 'ar'
  if (localStorage.getItem('language'))
    selectedLang = localStorage.getItem('language')

  return selectedLang
}

// Use
export const lang = (key) => {
  const result = text?.[key.toLowerCase()]?.[getLang()]
  return result ? result : key
}

// Initialize language
const init = () => {
  const lang = getLang()
  const html = document.querySelector('html')
  // Set language class to html
  html.classList.add(lang)
  html.setAttribute('lang', lang)
  document.body.setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr')
}

// Check if it's an arabic text
export function isArabic(text) {
  var arabic = /[\u0600-\u06FF]/
  return arabic.test(text)
}

export default init
