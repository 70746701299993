import React from 'react'
import { Link } from 'react-router-dom'
import { generateAvatar } from '../../hooks/Avatar'
import { formatDate } from '../../hooks/Date'
import { resizeImage } from '../../hooks/Photo'
import { isArabic, lang } from '../../language'

const UserItem = ({
  name,
  photo,
  username,
  email,
  role,
  createdAt,
  updatedAt,
}) => {
  createdAt = formatDate(createdAt)
  updatedAt = formatDate(updatedAt)

  return (
    <Link
      to={`/users/${username}`}
      className="mb-2 bg-white shadow lg:w-full w-max flex items-center flex-wrap table-fixed px-4 py-3 rounded-lg hover:bg-gray-50 transition duration-100"
    >
      <div className="lg:w-2/5 min-w-[300px] w-[300px] item flex items-center gap-3">
        {photo === '' || photo === null ? (
          <div
            className={`avatar ${
              !isArabic(name) ? 'font-sans' : 'font-noto-sans-arabic'
            }`}
          >
            {generateAvatar(name)}
          </div>
        ) : (
          <img
            src={resizeImage(photo, 40, 40)}
            alt=""
            className="w-10 h-10 rounded-full object-cover"
          />
        )}
        <div>
          <div
            className={`font-medium text-gray-800 ${
              !isArabic(name) ? 'font-sans' : 'font-noto-sans-arabic'
            }`}
          >
            {name}
          </div>
          <div
            className={`text-sm text-gray-600 ${
              !isArabic(email) ? 'font-sans' : 'font-noto-sans-arabic'
            }`}
          >
            {email}
          </div>
        </div>
      </div>
      <div className="lg:w-1/5 min-w-[150px] w-[150px] item">
        <div
          className={`font-medium text-gray-800 ${
            !isArabic(username) ? 'font-sans' : 'font-noto-sans-arabic'
          }`}
        >
          @{username}
        </div>
        <div
          className={`text-sm text-gray-600 ${
            !isArabic(role) ? 'font-sans' : 'font-noto-sans-arabic'
          }`}
        >
          {lang(role)}
        </div>
      </div>
      <div className="lg:w-1/5 min-w-[150px] w-[150px] item">
        <div className="text-gray-600 font-sans">{createdAt}</div>
      </div>
      <div className="lg:w-1/5 min-w-[150px] w-[150px] item">
        <div className="text-gray-600 font-sans">{updatedAt}</div>
      </div>
    </Link>
  )
}

export default UserItem
