import { lang } from '../language'

export const beautify = (timestamp) => {
  var monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  timestamp = new Date(timestamp)
  let date = timestamp.getDate()
  if (date < 10) date = '0' + date
  let month = monthNames[timestamp.getMonth()].substring(0, 3)
  var hours = timestamp.getHours()
  var minutes = timestamp.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  return `${date} ${month} ${hours}:${minutes} ${ampm}`
}

// Converting date to today, yesterday etc. Like WhatsApp
export const moment = (date, isTime = false) => {
  const today = 86400000
  const time = new Date(new Date(date).toDateString()).getTime()
  const current = new Date(new Date().toDateString()).getTime()
  const difference = current - time

  if (difference < today) return isTime !== false ? isTime : lang('Today')
  else if (difference >= today && difference < today * 2)
    return lang('Yesterday')
  else
    return `${
      new Date(time).getDate() < 10
        ? '0' + new Date(time).getDate()
        : new Date(time).getDate()
    }/${new Date(time).getMonth() + 1}/${new Date(time).getFullYear()}`
}

// Formating a date
export const formatDate = (date) => {
  date = new Date(date)
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}
