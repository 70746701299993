import React from 'react';
import {
  AudioIcon,
  DocIcon,
  FileIcon,
  PdfIcon,
  VideoIcon,
} from '../../assets/images';
import { ArrowDown } from '../../assets/icons';
import { beautify } from '../../hooks/Date';
import format from '../../hooks/WhatsAppTextFormatter';
import DateDivider from './DateDivider';
import { isArabic } from '../../language';

const MessageItem = ({ agent, message, time = null, showTime }) => {
  message.time = beautify(time.current);

  // .getTime() returns milliseconds
  const previousTime = new Date(time.previous).getTime(); // Previous chat sent time
  const currentTime = new Date(time.current).getTime(); // Current chat sent time
  let betweenTime = currentTime - previousTime;

  if (!showTime) {
    // Comparing previous and current time, if it's > 10 minutes == (600000 milliseconds). Just show the time
    if (betweenTime > 600000) {
      showTime = true;
    }
  }

  // Calculating date divider. Checking if betweenTime is greater than a day (86400000 ms)
  const dividerBetweenTime =
    new Date(new Date(time.current).toDateString()).getTime() -
    new Date(new Date(time.previous).toDateString()).getTime();
  const showDivider = dividerBetweenTime >= 86400000;

  // Media
  let media = null;
  if (message.media_uri && message.media_uri !== '') {
    // Checking media type, and changing icon based on that information
    const icon = (mime_type) => {
      if (mime_type.indexOf('image/') === 0) return message.media_uri;
      else if (mime_type.indexOf('video/') === 0) return VideoIcon;
      else if (mime_type.indexOf('audio/') === 0) return AudioIcon;
      else if (mime_type.indexOf('application/pdf') === 0) return PdfIcon;
      else if (
        mime_type.indexOf('application/msword') === 0 ||
        mime_type.indexOf(
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) === 0
      )
        return DocIcon;
      else return FileIcon;
    };

    media = (
      <div className="flex items-center max-w-[290px] w-full">
        <div className="flex items-center w-[calc(100%-3.25rem)]">
          <img src={icon(message.media_type)} alt="" className="w-8 h-8 mr-2" />
          <div>{message.body !== '' ? message.body : 'No Name'}</div>
        </div>
        <a
          href={message.media_uri}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-500 w-8 h-8 rounded-full border border-gray-300 flex items-center justify-center ml-5 cursor-pointer"
        >
          {<ArrowDown width={18} height={18} />}
        </a>
      </div>
    );
  }

  return (
    <>
      {showDivider && <DateDivider date={time.current} />}
      <div className={`message-items ${message.direction}`}>
        <div className="time">
          {message.profile_name !== null ? (
            <>
              <span
                className="font-sans"
                style={{ direction: 'ltr', display: 'inline-block' }}
              >
                {message.profile_name}
              </span>
              &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
            </>
          ) : (
            ''
          )}
          {showTime && (
            <span
              className="font-sans"
              style={{ direction: 'ltr', display: 'inline-block' }}
            >
              {message.time}
            </span>
          )}
        </div>
        <div className="message-item">
          {media !== null ? (
            <div className="overflow-hidden">{media}</div>
          ) : (
            <span
              className={`text ${
                !isArabic(message.body) ? 'font-sans' : 'font-noto-sans-arabic'
              }`}
              dangerouslySetInnerHTML={{ __html: format(message.body) }}
            ></span>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageItem;
