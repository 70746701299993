import React from 'react'
import { Home } from '../../assets/icons'
import { Link } from 'react-router-dom'
import { getLang, lang } from '../../language'

export const PageHeading = ({ children }) => {
  const breadcrumbs = []
  const links = window.location.href
    .replace(window.location.origin, '')
    .split('/')
    .splice(1)
  links.map((item, index) => {
    const oldPath =
      breadcrumbs[index - 1] !== undefined ? breadcrumbs[index - 1]?.href : ''
    breadcrumbs.push({
      text: item.charAt(0).toUpperCase() + item.substring(1),
      href: oldPath + '/' + item,
    })
  })

  return (
    <div className="mb-5">
      <nav className="flex mb-4" aria-label="Breadcrumb">
        <ol
          role="list"
          className={`bg-white rounded-md shadow px-6 flex space-x-4 ${
            getLang() === 'ar' ? 'ml-auto' : 'mr-auto'
          }`}
          style={{ direction: 'ltr' }}
        >
          <li className="flex">
            <div className="flex items-center">
              <a href="#" className="text-gray-400 hover:text-gray-500">
                <Home width="20px" height="20px" />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {breadcrumbs.map((item, index) => (
            <li className="flex" key={index}>
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-6 h-full text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <Link
                  to={item.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 font-sans"
                >
                  {lang(item.text)}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>

      {children}
    </div>
  )
}
