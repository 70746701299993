import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { generateAvatar } from '../../hooks/Avatar'
import { moment } from '../../hooks/Date'
import format from '../../hooks/WhatsAppTextFormatter'
import { getLang, isArabic } from '../../language'

const ChatItem = ({
  chatId = '',
  name = '',
  lastMessage = '',
  lastActivity,
  unreadMessages = 0,
}) => {
  const params = useParams()
  const avatar = generateAvatar(name)
  lastActivity = new Date(lastActivity)
  lastActivity = moment(
    lastActivity,
    lastActivity.getHours() + ':' + lastActivity.getMinutes()
  )

  return (
    <li>
      <Link
        to={`/inbox/${chatId}`}
        className={`chat-item ${
          params.chatId !== null && params.chatId === chatId && 'active'
        } ${getLang() === 'ar' ? 'pr-4 border-l-2' : 'pl-4 border-r-2'}`}
      >
        {/* Left */}
        <div
          className={`avatar ${
            !isArabic(avatar) ? 'font-sans' : 'font-noto-sans-arabic'
          }`}
        >
          {avatar}
        </div>
        {/* Right */}
        <div className="content">
          <div
            className={`name ${
              !isArabic(name) ? 'font-sans' : 'font-noto-sans-arabic'
            }`}
          >
            {name}
            <div
              className={`time ${
                !isArabic(lastActivity) ? 'font-sans' : 'noto-sans-arabic'
              } ${getLang() === 'ar' ? 'left-0' : 'right-0'}`}
            >
              {lastActivity}
            </div>
          </div>
          <div
            className={`last-message ${getLang() === 'ar' ? 'pl-8' : 'pr-8'} ${
              !isArabic(lastMessage) ? 'font-sans' : 'font-noto-sans-arabic'
            }`}
            title={lastMessage}
            dangerouslySetInnerHTML={{ __html: format(lastMessage) }}
          ></div>
          {unreadMessages > 0 && (
            <div className="unread font-sans" id={`unread${chatId}`}>
              {unreadMessages > 9 ? '9+' : unreadMessages}
            </div>
          )}
        </div>
      </Link>
    </li>
  )
}

export default ChatItem
