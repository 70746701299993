const generateAvatar = (string) => {
  let avatar = ''
  const names = string.split(' ')
  names.slice(0, 2).forEach((element) => {
    avatar += element.substring(0, 1)
  })
  return avatar.toUpperCase()
}

export { generateAvatar }
