import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/Base/Button'
import Container from '../../components/Base/Container'
import Card from '../../components/Base/Card'
import UserItem from '../../components/Users/UserItem'
import { sendRequest } from '../../hooks/API'
import Toast from '../../hooks/Toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../components/Base/Loading'
import { AuthContext } from '../../contexts/AuthContext'
import { lang } from '../../language'

const Users = () => {
  const [users, setUsers] = useState(null)
  const [information, setInformation] = useState(null)
  const { auth } = useContext(AuthContext)

  useEffect(() => {
    // Checking permission
    if (auth.role === 'agent') {
      window.location.replace('/')
    }
  }, [auth])

  const fetchUsers = async (defaultLoad = true) => {
    let endpoint = `/users`
    if (!defaultLoad) {
      endpoint += `?page=${information.page + 1}`
    }
    // Fetching all users
    const resp = await sendRequest(endpoint)
    // Handling error
    if (resp.statusText !== 'OK') {
      return Toast('Something went wrong.', 'error')
    }
    if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
    if (!defaultLoad) {
      const totalUsers = users.concat(resp.data.data)
      setUsers(totalUsers)
    } else {
      setUsers(resp.data.data)
    }
    setInformation(resp.data.information)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <Container>
      <Card.Wrapper>
        <Card.Header>
          <div className="flex justify-between items-center flex-wrap gap-3">
            <div>
              <h1 className="page-heading">{lang('manage-users')}</h1>
              <p className="page-description">{lang('users-description')}</p>
            </div>
            <Link to="/users/add">
              <Button text={lang('add-user')} />
            </Link>
          </div>
        </Card.Header>
        {/* Content */}
        {users !== null && (
          <div>
            <InfiniteScroll
              dataLength={users.length}
              next={() => fetchUsers(false)}
              hasMore={information.page !== information.total_pages}
              loader={<Loading />}
              scrollableTarget="conversations"
            >
              <div className="bg-white shadow lg:w-full w-max flex items-center flex-wrap table-fixed px-4 py-3 rounded-lg mb-2">
                <div className="lg:w-2/5 min-w-[300px] w-[300px] item text-gray-800 font-medium">
                  {lang('name')}
                </div>
                <div className="lg:w-1/5 min-w-[150px] w-[150px] item text-gray-800 font-medium">
                  {lang('username')}
                </div>
                <div className="lg:w-1/5 min-w-[150px] w-[150px] item text-gray-800 font-medium">
                  {lang('created-at')}
                </div>
                <div className="lg:w-1/5 min-w-[150px] w-[150px] item text-gray-800 font-medium">
                  {lang('updated-at')}
                </div>
              </div>
              {users.map((user, index) => (
                <UserItem
                  key={index}
                  name={user.profile_name}
                  photo={user.photo}
                  username={user.username}
                  email={user.email}
                  role={user.role}
                  createdAt={user.created_at}
                  updatedAt={user.updated_at}
                />
              ))}
            </InfiniteScroll>
          </div>
        )}
      </Card.Wrapper>
    </Container>
  )
}

export default Users
