export const validateLogin = () => {
  const token = document.cookie
    .split('; ')
    .find((row) => row.startsWith('token='))
    ?.split('=')[1]

  if (token && token !== '') {
    return token
  } else {
    return false
  }
}

// Function to logout current user, if already logged in
export const logout = () => {
  if (validateLogin()) {
    document.cookie = 'token=;max-age=0'
    window.location.replace('/auth/login')
  }
  return
}
