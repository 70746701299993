import axios from 'axios'
import { env } from '../config'

export const sendRequest = async (
  endpoint,
  method = 'GET',
  options = {},
  headers = {}
) => {
  return await axios({
    method: method,
    url: `${env.API_URI + endpoint}`,
    headers: {
      api_key: env.API_KEY,
      ...headers,
    },
    ...options,
  })
}
