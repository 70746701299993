import React from 'react'

const Button = ({ text, size, theme, onClick = () => {} }) => {
  return (
    <button className={`button ${size} ${theme}`} onClick={onClick}>
      {text}
    </button>
  )
}

export default Button
