// React router dom
import { createBrowserRouter, RouterProvider } from "react-router-dom"
// Layout
import Layout from "./components/Layout"
// Pages
import Inbox from "./pages/Inbox"
import Contacts from "./pages/Contacts"
import Users from "./pages/Users/Index"
import AddUser from "./pages/Users/AddUser"
import EditUser from "./pages/Users/EditUser"
import Login from "./pages/Auth/Login"
import { AuthContext } from "./contexts/AuthContext"
import { useEffect, useState } from "react"
import { validateLogin } from "./hooks/Auth"
import { sendRequest } from "./hooks/API"
import Toast from "./hooks/Toast"
import { NavLinks } from "./hooks/Nav"
// Socket
import Socket from "./hooks/Socket"
// Push notification
import addNotification from "react-push-notification"
// Icon
import { Icon as Logo } from "./assets/images"
import setLanguage from "./language"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Inbox />,
      },
      {
        path: "/inbox",
        element: <Inbox />,
      },
      {
        path: "/inbox/:chatId",
        element: <Inbox />,
      },
      {
        path: "/contacts",
        element: <Contacts />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/add",
        element: <AddUser />,
      },
      {
        path: "/users/:username",
        element: <EditUser />,
      },
    ],
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
])

function App() {
  const [auth, setAuth] = useState(true)

  // Validate permission
  const checkPermission = (role) => {
    const link = NavLinks.filter((item) => {
      return item.href === window.location.pathname
    })[0]?.permissions.includes(role)
    // Redirecting to dashboard
    if (link === false) return window.location.replace("/")
  }

  useEffect(() => {
    const login = async () => {
      // Fethcing all the user details, if user is logged in
      if (validateLogin()) {
        // Fetching user information from database based on token
        const resp = await sendRequest(`/users/login`, "POST", {
          data: { token: validateLogin() },
        })
        // Handling errors
        if (resp.statusText !== "OK")
          return Toast("Something went wrong.", "error")
        if (resp.data.status === "error")
          return Toast(resp.data.message, "error")
        // Setting auth context value to user information. So, I can access it from any page
        setAuth(resp.data.data)
        checkPermission(resp.data.data.role)
      } else {
        setAuth(false)
      }
    }
    login()

    // Initialize language settings
    setLanguage()
  }, [])

  // Push notification
  useEffect(() => {
    Socket((socket) => {
      socket.on("receive-message", (message) => {
        if (message.AgentId === auth.id || auth.role === "admin") {
          // Sending push notification
          addNotification({
            icon: window.location.origin + Logo,
            title: message.ProfileName,
            subtitle: `+${message.WaId}`,
            message: message.Body,
            theme: "darkblue",
            native: true, // When using native, OS will handle theming
          })
        }
      })
    })
  }, [auth])

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <RouterProvider router={router} />
    </AuthContext.Provider>
  )
}

export default App
