import React, { useContext, useEffect, useState } from 'react'
import Button from '../../components/Base/Button'
import Container from '../../components/Base/Container'
import Card from '../../components/Base/Card'
import Input from '../../components/Base/Input'
import Select from '../../components/Base/Select'
import Toast from '../../hooks/Toast'
import { sendRequest } from '../../hooks/API'
import { validateEmail } from '../../hooks/String'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { lang } from '../../language'

const AddUser = () => {
  const [formData, setFormData] = useState({
    // Default values
    profile_name: '',
    username: '',
    email: '',
    password: '',
    role: '',
  })
  const navigate = useNavigate()
  const { auth } = useContext(AuthContext)

  useEffect(() => {
    // Checking permission
    if (auth.role === 'agent') {
      window.location.replace('/')
    }
  }, [auth])

  // Handling form data
  const handleFormData = (event) => {
    let data = { [event.target.name]: event.target.value }
    if (event.target.name === 'photo') {
      data = { [event.target.name]: event.target.files[0] }
    }
    setFormData((prev) => ({
      ...prev,
      ...data,
    }))
  }

  // Create user on button click
  const createUser = async (e) => {
    e.preventDefault()

    // Validating form input
    if (
      formData.profile_name === '' ||
      formData.username === '' ||
      formData.email === '' ||
      formData.password === ''
    ) {
      return Toast('Missing required fields.', 'error')
    }

    // Validating email
    if (!validateEmail(formData.email)) {
      return Toast('Invalid email format.', 'error')
    }

    // Creating form data object
    let newFormData = new FormData()

    // Appending all text fields from formData state
    for (let key in formData) {
      newFormData.append(key, formData[key])
    }

    // Sending request to server
    const resp = await sendRequest(`/users`, 'POST', { data: newFormData })
    if (resp.statusText !== 'OK') return Toast('Something went wrong.', 'error')
    // Checking if there is any error
    if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
    // Showing success messages
    Toast(`New account created for ${formData.profile_name}`, 'success')
    // Redirecting back to users page
    return navigate('/users')
  }

  return (
    <Container>
      <Card.Wrapper>
        <Card.Header>
          <div className="flex justify-between items-center flex-wrap">
            <div>
              <h1 className="page-heading">{lang('add-user')}</h1>
            </div>
          </div>
        </Card.Header>

        <div className="max-w-2xl w-full">
          <form>
            <div className="grid sm:grid-cols-2 gap-2 mb-4">
              <Input
                theme="white"
                placeholder={lang('full-name')}
                name="profile_name"
                value={formData?.profile_name}
                onChange={handleFormData}
              />
              <Input
                theme="white"
                placeholder={lang('username')}
                name="username"
                value={formData?.username}
                onChange={handleFormData}
              />
              <Input
                theme="white"
                type="email"
                placeholder={lang('email-address')}
                name="email"
                value={formData?.email}
                onChange={handleFormData}
              />
              <Input
                theme="white"
                type="password"
                placeholder={lang('password')}
                name="password"
                value={formData?.password}
                onChange={handleFormData}
              />
              <Select
                theme="white"
                placeholder={lang('role')}
                name="role"
                value={formData?.role}
                options={[
                  { label: 'Admin', value: 'admin' },
                  { label: 'Agent', value: 'agent' },
                ]}
                onChange={handleFormData}
              />
              <Input
                type="file"
                accept="image/*"
                theme="white font-sans"
                name="photo"
                onChange={handleFormData}
              />
            </div>
            <Button text={lang('create-user')} onClick={createUser} />
          </form>
        </div>
      </Card.Wrapper>
    </Container>
  )
}

export default AddUser
