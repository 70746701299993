import React from 'react'

const Input = (props) => {
  return (
    <>
      <div className="input-box">
        {props.preview && <div className="preview">{props.preview}</div>}
        <input
          type={`${props.type ? props.type : 'text'}`}
          className={`input ${props.theme}`}
          {...props}
        />
      </div>
    </>
  )
}

export default Input
