import React from 'react'
import { PageHeading } from './PageHeading'

const Wrapper = ({ children }) => {
  return <div>{children}</div>
}

const Header = ({ children }) => {
  return <PageHeading>{children}</PageHeading>
}

const Body = ({ children, className }) => {
  return (
    <div className={`bg-white shadow-sm p-4 rounded-lg ${className}`}>
      {children}
    </div>
  )
}

export default { Wrapper, Header, Body }
