import { env } from '../config'

// Function to resize images
export const resizeImage = (url, width, height) => {
  // Just to resize images if it's an production version
  if (env.NODE_ENV === 'production')
    return `https://images.weserv.nl/?url=${url}&w=${width}&h=${height}`

  return url
}
