import React, { useContext, useState } from "react"
import { Link, useHref } from "react-router-dom"
import { Menu } from "../assets/icons"
import { Logo } from "../assets/images"
import { AuthContext } from "../contexts/AuthContext"
import { generateAvatar } from "../hooks/Avatar"
import { NavLinks } from "../hooks/Nav"
import { resizeImage } from "../hooks/Photo"
import { getLang, isArabic } from "../language"
// Flags
import SaudiArabiaFlag from "../assets/images/saudi-arabia-flag.png"
import USAFlag from "../assets/images/usa-flag.png"

const Navbar = () => {
  const { auth } = useContext(AuthContext)
  const [showNavbar, setShowNavbar] = useState(false)
  const links = NavLinks.filter((item) => {
    return item.permissions !== undefined
      ? item.permissions.includes(auth.role)
      : true
  })

  // Change language
  const changeLang = () => {
    const lang = getLang()
    if (lang === "ar") {
      localStorage.setItem("language", "en")
    } else {
      localStorage.setItem("language", "ar")
    }
    window.location.reload()
  }

  return (
    <>
      <button
        className="slg:hidden button w-8 h-8 flex items-center justify-center p-0 fixed right-3 top-3 text-white z-50"
        onClick={() => setShowNavbar(!showNavbar)}
      >
        <Menu width={19} />
      </button>
      {showNavbar && (
        <div
          className="fixed top-0 left-0 w-screen h-screen bg-black/80 backdrop-blur z-30"
          onClick={() => setShowNavbar(!showNavbar)}
        ></div>
      )}
      <nav
        className={`${
          !showNavbar ? "-right-[250px]" : "right-0"
        } slg:max-w-full bg-white border-b px-4 py-3 slg:flex justify-between items-center flex-wrap slg:h-[69px] slg:static fixed top-0 slg:w-full max-w-[250px] w-full h-full block z-40 slg:shadow-none shadow-xl transition-all duration-300`}
      >
        {/* Left */}
        <div className="slg:flex block items-center flex-wrap slg:w-9/12 w-full slg:mb-0 mb-8">
          <Link to="/" className={`${getLang() === "ar" ? "ml-10" : "mr-10"}`}>
            <img src={Logo} alt="" className="h-10 mr-auto" />
          </Link>

          <ul className="nav-links flex items-center flex-wrap gap-3 slg:flex-row flex-col">
            {links?.map((link, key) => (
              <NavItem
                {...link}
                key={key}
                onClick={() => setShowNavbar(false)}
              />
            ))}
          </ul>
        </div>
        {/* Right */}
        <div className="slg:w-3/12 w-full flex justify-end">
          <ul className="flex slg:justify-end justify-center items-center flex-wrap w-full gap-4">
            <li>
              <Link
                to={`/users/${auth.username}`}
                className={`${
                  getLang() === "ar" ? "slg:flex-row-reverse" : "slg:flex-row"
                } text-gray-800 flex slg:text-left flex-col justify-center text-center items-center gap-4`}
              >
                {auth.photo === "" || auth.photo === null ? (
                  <div
                    className={`avatar ${
                      !isArabic(auth.profile_name)
                        ? "font-sans"
                        : "font-noto-sans-arabic"
                    }`}
                  >
                    {generateAvatar(auth.profile_name)}
                  </div>
                ) : (
                  <img
                    src={resizeImage(auth.photo, 40, 40)}
                    alt=""
                    className="w-10 h-10 rounded-full object-cover object-center"
                  />
                )}
                <div>
                  <div
                    className={`font-semibold text-gray-900 ${
                      !isArabic(auth.profile_name)
                        ? "font-sans"
                        : "font-noto-sans-arabic"
                    }`}
                  >
                    {auth.profile_name}
                  </div>
                  <div className="text-gray-700 text-sm font-normal font-sans">
                    {auth.email}
                  </div>
                </div>
              </Link>
            </li>
            <li
              className="text-slg font-semibold font-sans text-gray-600 cursor-pointer flex"
              onClick={changeLang}
            >
              {getLang() !== "ar" ? (
                <img src={SaudiArabiaFlag} className="w-8" alt="" />
              ) : (
                <img src={USAFlag} className="w-8" alt="" />
              )}
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

const NavItem = ({ text = "", href = "/", icon, onClick = () => {} }) => {
  const pathname = useHref()

  return (
    <li className="nav-item slg:w-auto w-full">
      <Link
        to={href}
        className={`nav-link ${
          pathname === href && "active"
        } slg:bg-transparent bg-gray-50`}
        onClick={onClick}
      >
        {icon && icon}
        {text}
      </Link>
    </li>
  )
}

export default Navbar
