const scrollBottom = (elementId = '', scrollHeight = null) => {
  if (document.getElementById(elementId)) {
    setTimeout(() => {
      document.getElementById(elementId).scrollTop =
        scrollHeight === null
          ? document.getElementById(elementId).scrollHeight
          : scrollHeight
    }, 0)
  }
}

export { scrollBottom }
