import React from 'react'

const Select = (props) => {
  return (
    <select className={`input ${props.theme}`} {...props}>
      {props.placeholder && (
        <option value="" disabled hidden>
          {props.placeholder}
        </option>
      )}
      {props?.options?.map((item, index) => (
        <option value={item.value} key={index}>
          {item.label}
        </option>
      ))}
    </select>
  )
}

export default Select
