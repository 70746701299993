import React, { useState, useEffect, useContext } from 'react'
import Container from '../../components/Base/Container'
import Card from '../../components/Base/Card'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/Base/Button'
import Input from '../../components/Base/Input'
import Select from '../../components/Base/Select'
import Toast from '../../hooks/Toast'
import { sendRequest } from '../../hooks/API'
import { validateEmail } from '../../hooks/String'
import { generateAvatar } from '../../hooks/Avatar'
import { resizeImage } from '../../hooks/Photo'
import { AuthContext } from '../../contexts/AuthContext'
import { getLang, isArabic, lang } from '../../language'

const UserView = () => {
  const { username } = useParams()
  const { auth } = useContext(AuthContext)
  const [formData, setFormData] = useState({
    // Default values
    profile_name: '',
    username: '',
    email: '',
    password: '',
    role: '',
  })
  const navigate = useNavigate()

  useEffect(() => {
    // Checking if it's an agent, and make sure he is not trying to change anyone else's profile
    const checkPermission = () => {
      if (auth.role === 'agent' && auth.username !== username) {
        window.location.replace('/')
      }
    }
    checkPermission()

    // Fetching user information
    const fetchUser = async () => {
      // Fetching all users
      const resp = await sendRequest(`/users/${username}`)
      // Handling error
      if (resp.statusText !== 'OK') {
        return Toast('Something went wrong.', 'error')
      }
      if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
      setFormData(() => {
        return {
          profile_name: resp.data.data.profile_name,
          username: resp.data.data.username,
          email: resp.data.data.email,
          password: '',
          role: resp.data.data.role,
          photoPreview: resp.data.data.photo,
        }
      })
    }
    fetchUser()
  }, [username, auth])

  // Handling form data
  const handleFormData = (event) => {
    // Agent can't change his own role
    if (auth.role === 'agent' && event.target.name === 'role') return

    let data = { [event.target.name]: event.target.value }
    if (event.target.name === 'photo') {
      // Update data variable
      data = {
        [event.target.name]: event.target.files[0],
        photoPreview: window.URL.createObjectURL(event.target.files[0]),
      }
    }
    setFormData((prev) => ({
      ...prev,
      ...data,
    }))
  }

  // Update user on button click
  const updateUser = async (e) => {
    e.preventDefault()

    // Validating form input
    if (
      formData.profile_name === '' ||
      formData.username === '' ||
      formData.email === ''
    ) {
      return Toast('Missing required fields.', 'error')
    }

    // Validating email
    if (!validateEmail(formData.email)) {
      return Toast('Invalid email format.', 'error')
    }

    // Creating form data object
    let newFormData = new FormData()

    // Appending all text fields from formData state
    for (let key in formData) {
      if (!(formData.password === '' && key === 'password')) {
        newFormData.append(key, formData[key])
      }
    }

    // Sending request to server
    const resp = await sendRequest(`/users/${formData.username}`, 'PUT', {
      data: newFormData,
    })
    if (resp.statusText !== 'OK') return Toast('Something went wrong.', 'error')
    // Checking if there is any error
    if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
    // Showing success messages
    Toast(`Account updated for ${formData.profile_name}`, 'success')
    // Redirecting back to users page, if it's an admin
    if (auth.role === 'admin') return navigate('/users')
  }

  // Deleting user
  const deleteUser = async (e) => {
    e.preventDefault()

    const confirm = window.confirm('Are you sure you want to delete the user?')
    if (confirm) {
      // Sending request to server
      const resp = await sendRequest(`/users/${formData.username}`, 'DELETE')
      if (resp.statusText !== 'OK')
        return Toast('Something went wrong.', 'error')
      // Checking if there is any error
      if (resp.data.status === 'error') return Toast(resp.data.message, 'error')
      // Showing success messages
      Toast(`Account deleted for ${formData.profile_name}`, 'success')
      // Redirecting back to users page
      return navigate('/users')
    }
  }

  return (
    <Container>
      <Card.Wrapper>
        <Card.Header>
          <div className="flex justify-between items-center flex-wrap">
            <div>
              <h1 className="page-heading">
                {lang('update')}{' '}
                <span
                  className={
                    !isArabic(formData.profile_name)
                      ? 'font-sans'
                      : 'font-noto-sans-arabic'
                  }
                >
                  {formData.profile_name}
                </span>
              </h1>
            </div>
          </div>
        </Card.Header>

        {formData !== null && (
          <div className="max-w-2xl w-full">
            <form>
              <div className="grid sm:grid-cols-2 gap-2 mb-4">
                <Input
                  theme={`white ${
                    !isArabic(formData?.profile_name)
                      ? 'font-sans'
                      : 'font-noto-sans-arabic'
                  }`}
                  placeholder={lang('full-name')}
                  name="profile_name"
                  value={formData?.profile_name}
                  onChange={handleFormData}
                />
                <Input
                  theme={`white ${
                    !isArabic(formData?.username)
                      ? 'font-sans'
                      : 'font-noto-sans-arabic'
                  }`}
                  placeholder={lang('username')}
                  name="username"
                  value={formData?.username}
                  readOnly={true}
                />
                <Input
                  theme={`white ${
                    !isArabic(formData?.email)
                      ? 'font-sans'
                      : 'font-noto-sans-arabic'
                  }`}
                  type="email"
                  placeholder={lang('email-address')}
                  name="email"
                  value={formData?.email}
                  onChange={handleFormData}
                />
                <Input
                  theme={`white ${
                    !isArabic(formData?.password)
                      ? 'font-sans'
                      : 'font-noto-sans-arabic'
                  }`}
                  type="password"
                  placeholder={lang('password')}
                  name="password"
                  value={formData?.password}
                  onChange={handleFormData}
                />
                <Select
                  theme="white"
                  placeholder={lang('role')}
                  name="role"
                  value={formData?.role}
                  options={[
                    { label: lang('admin'), value: 'admin' },
                    { label: lang('agent'), value: 'agent' },
                  ]}
                  onChange={handleFormData}
                />
                <div>
                  <Input
                    type="file"
                    accept="image/*"
                    theme="white font-sans"
                    name="photo"
                    onChange={handleFormData}
                    preview={
                      formData.photoPreview === '' ||
                      formData.photoPreview === null ? (
                        <div
                          className={`avatar rounded w-8 h-8 text-xs ${
                            !isArabic(formData.profile_name)
                              ? 'font-sans'
                              : 'font-noto-sans-arabic'
                          }`}
                        >
                          {generateAvatar(formData.profile_name)}
                        </div>
                      ) : (
                        <img
                          src={resizeImage(formData.photoPreview, 32, 32)}
                          alt=""
                          className="w-8 rounded object-cover"
                        />
                      )
                    }
                  />
                </div>
              </div>
              <Button text={lang('update-user')} onClick={updateUser} />
              <div
                className={`${
                  getLang() === 'ar' ? 'mr-3' : 'ml-3'
                } inline-block`}
              >
                <Button
                  text={lang('delete-user')}
                  theme="danger"
                  onClick={deleteUser}
                />
              </div>
              <small
                className={`md:inline-block block ${
                  getLang() === 'ar' ? 'md:mr-3' : 'md:ml-3'
                } md:mt-0 mt-3`}
              >
                {lang('leave-password')}
              </small>
            </form>
          </div>
        )}
      </Card.Wrapper>
    </Container>
  )
}

export default UserView
