import React, { useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Logo } from '../../assets/images'
import { AuthContext } from '../../contexts/AuthContext'
import { sendRequest } from '../../hooks/API'
import { validateLogin } from '../../hooks/Auth'
import { validateEmail } from '../../hooks/String'
import Toast from '../../hooks/Toast'
import { getLang, lang } from '../../language'

const Login = () => {
  const { auth, setAuth } = useContext(AuthContext)
  const navigate = useNavigate()
  const emailRef = useRef()
  const passwordRef = useRef()
  const rememberMeRef = useRef()

  // Checking if user is already logged in
  useEffect(() => {
    if (auth !== false) {
      navigate('/')
    }
  }, [auth])

  const handleLogin = async (e) => {
    e.preventDefault()

    // Extracting values
    const data = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      rememberMe: rememberMeRef.current.checked,
    }

    // Checking all fields are filled
    if (data.email === '' || data.password === '')
      return Toast('Missing email or password.', 'error')

    // Checking email format
    if (!validateEmail(data.email))
      return Toast('Invalid email format.', 'error')

    // Sending request to server to login
    const resp = await sendRequest('/users/login', 'POST', {
      data: { email: data.email, password: data.password },
    })
    // Handling error response
    if (resp.statusText !== 'OK') return Toast('Something went wrong.', 'error')
    if (resp.data.status === 'error') return Toast(resp.data.message, 'error')

    // Cookie time will be updated based on remember me
    let cookieString = `token=${resp.data.data.password};path=/`
    if (data.rememberMe === true) {
      // Formula for getting expiries date = "60*60*24*30" 30 is the days
      cookieString += `;max-age=${60 * 60 * 24 * 30}`
    }
    // Set cookies to local storage
    document.cookie = cookieString
    // Setting context
    setAuth(resp.data.data)
    // Redirecting the user to dashboard
    return navigate('/')
  }

  return (
    <div className="flex bg-white min-h-screen">
      <ToastContainer />
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={Logo} alt="WhatsAPI" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              {lang('sign-in-title')}
            </h2>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {lang('email-address')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      ref={emailRef}
                      className="font-sans appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark sm:text-sm transition-all duration-100"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {lang('password')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="password"
                      ref={passwordRef}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark sm:text-sm transition-all duration-100"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      ref={rememberMeRef}
                      className="h-4 w-4 text-primary-dark focus:ring-primary-dark-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className={`${
                        getLang() === 'ar' ? 'mr-2' : 'ml-2'
                      } block text-sm text-gray-900`}
                    >
                      {lang('remember-me')}
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    onClick={handleLogin}
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-dark hover:bg-primary-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark transition-all duration-100"
                  >
                    {lang('sign-in')}
                  </button>
                  <div className="text-sm font-medium text-gray-600 mt-3">
                    {lang('forgot-password')}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://source.unsplash.com/random/1920x1080/?saudi"
          alt=""
        />
      </div>
    </div>
  )
}

export default Login
