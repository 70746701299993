import { toast as toastify } from 'react-toastify'

const Toast = (text, type = 'info') => {
  toastify(text, {
    type: type,
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export default Toast
