import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InboxContext, NewChatContext } from '../../contexts/InboxContext';
import { generateAvatar } from '../../hooks/Avatar';
import MessageItem from './MessageItem';
// Icons
import { Send, Attach, Emoji } from '../../assets/icons';
import { scrollBottom } from '../../hooks/Scroll';
// Request related
import { sendRequest } from '../../hooks/API';
import Toast from '../../hooks/Toast';
import { beautify } from '../../hooks/Date';
import Socket from '../../hooks/Socket';
// Emoji picker
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../Base/Loading';
import { env } from '../../config';
import { wait } from '../../hooks/Promise';
// Lang
import { isArabic, lang } from '../../language';
import { AuthContext } from '../../contexts/AuthContext';

const ChatBox = () => {
  const { chatHistory, currentChat, methods } = useContext(InboxContext); // chatHistory = { chatHistory, setChatHistory }: state values
  const { auth } = useContext(AuthContext);
  const { chatId } = useParams(); // chatId = wa_id (whatsapp number)
  const [newChat, setNewChat] = useState('');
  const [files, setFiles] = useState(null);
  const [information, setInformation] = useState({
    currentPage: 1,
    nextPage: 2,
    totalPages: 2,
    total: 0,
  });

  // Fetching chat and store it to conversation state
  const fetchChat = async (defaultLoad = true, oldChat = false) => {
    let scrollPosition = null;
    if (!defaultLoad) {
      scrollPosition = document.getElementById('chat-body').scrollTop;
      // Wait for 1s, if it's development mode
      if (env.NODE_ENV === 'development') wait(1000);
    }

    let waId = window.location.pathname.split('/');
    waId = waId[waId.length - 1];
    if (waId === 'inbox') return;

    let endpoint = `/chat/${waId}`;
    // Adding page number if it's not default load
    if (!defaultLoad) endpoint += `?page=${information.nextPage}`;

    // If there is no chat with the same id, update the chat history and set it to currentChat
    if (
      (chatId && !chatHistory.chatHistory?.[chatId]) ||
      defaultLoad === false ||
      oldChat
    ) {
      // Fetching chat
      const response = await sendRequest(endpoint, 'GET');
      // Handling error
      if (response.data.status === 'error') {
        return Toast(response.data.message, 'error');
      }
      let history = response.data.data;
      // Updating states
      if (!defaultLoad) {
        const finalData = [
          ...history.messages.reverse(),
          ...chatHistory.chatHistory[chatId].messages,
        ];
        history.messages = finalData;
      } else {
        // Reversing history.messages
        history.messages = history.messages.reverse();
      }
      // Updating chat history
      chatHistory.setChatHistory((prev) => ({ ...prev, [chatId]: history }));
      // Updating currentChat to the chat history
      currentChat.setCurrentChat(history);
      // Updating local chat information
      // If it's not a default load just update the next page (this will help for infinite scrolling)
      setInformation((prev) => ({
        ...prev,
        currentPage: response.data.information.page,
        nextPage:
          response.data.information.page ===
          response.data.information.total_pages
            ? response.data.information.page
            : response.data.information.page + 1,
        totalPages: response.data.information.total_pages,
        total: response.data.information.total_rows,
      }));
    } else {
      // Updating currentChat to the chat history
      currentChat.setCurrentChat(chatHistory.chatHistory?.[chatId]);
    }

    // Set scroll height to a specific position of the chat body
    scrollBottom('chat-body', scrollPosition);
  };

  useEffect(() => {
    fetchChat(true, true);
  }, [chatId]);

  useEffect(() => {
    if (auth.id) {
      // Socket.io
      Socket((socket) => {
        socket.on('receive-message', (message) => {
          let waId = window.location.pathname.split('/');
          waId = waId[waId.length - 1];
          console.log(`notification waid: ${waId}`);
          if (waId === message.WaId) {
            fetchChat(true, true);
          }
          if (auth.role === 'agent') {
            methods.fetchConversations(true, auth.id);
          } else {
            methods.fetchConversations(true);
          }
        });
      });
    }
  }, [auth]);

  // Handling send button
  const handleSendButton = async () => {
    if (newChat === '' && (files === null || files.length === 0)) {
      return document.getElementById('send-input')?.focus();
    }

    const chat = {
      body: newChat,
      date_sent: new Date(),
      direction: 'outbound-api',
      id: currentChat.currentChat.messages.length,
      sent_by: auth.id,
      status: 'delivered',
      time: beautify(new Date()),
      wa_id: currentChat.currentChat.messages[0].wa_id,
      account_sid: currentChat.currentChat.messages[0].account_sid,
    };

    let formData = new FormData();

    // Appending all text fields from chat object
    for (let key in chat) {
      formData.append(key, chat[key]);
    }
    // Appending files
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
    }

    // Updating chatHistory
    chatHistory.setChatHistory((prev) => ({
      ...prev,
      [chatId]: {
        ...prev[chatId],
        messages: [...prev[chatId].messages, chat],
      },
    }));
    // Updating currentChat
    currentChat.setCurrentChat((prev) => ({
      ...prev,
      messages: [...prev.messages, chat],
    }));

    // Remove text from state
    setNewChat('');
    // Change focus to input field
    document.getElementById('send-input')?.focus();
    // Scroll down to bottom of chat-body
    scrollBottom('chat-body');

    // Sending request to create new chat
    const resp = await sendRequest(
      '/chat',
      'POST',
      {
        data: formData,
      },
      { 'Content-Type': 'multipart/form-data' }
    );

    // Updating conversations
    methods.fetchConversations();
    // Removing text from search box
    document.getElementById('search-conv').value = '';

    // Remove files data from files state
    setFiles(null);

    // Handling error response
    if (resp.data.status === 'error') Toast(resp.data.message);
  };

  return (
    <div
      className={`overflow-auto h-full flex flex-col ${
        chatId === undefined && 'lg:block hidden'
      }`}
    >
      {currentChat.currentChat && (
        <NewChatContext.Provider
          value={{
            newChat,
            setNewChat,
            handleSendButton,
            information,
            fetchChat,
            currentChat,
            files,
            setFiles,
          }}
        >
          <Header
            name={currentChat.currentChat.name}
            number={currentChat.currentChat.number}
          />
          <Body />
          <Footer />
        </NewChatContext.Provider>
      )}
    </div>
  );
};

const Header = ({ name = '', number = '' }) => {
  return (
    <div className="bg-white shadow-lg shadow-black/[0.02] border-b py-2 flex items-center">
      <div className="chat-item hover:bg-transparent xl:pl-8">
        {/* Left */}
        <div
          className={`avatar w-12 h-12 text-lg ${
            !isArabic(name) ? 'font-sans' : 'font-noto-sans-arabic'
          }`}
        >
          {generateAvatar(name)}
        </div>
        {/* Right */}
        <div className="content border-transparent" style={{ paddingBlock: 0 }}>
          <div
            className={`name ${
              !isArabic(name) ? 'font-sans' : 'font-noto-sans-arabic'
            }`}
          >
            {name}
          </div>
          <div className="last-message font-sans">{number}</div>
        </div>
      </div>
    </div>
  );
};

const Body = () => {
  const { information, fetchChat, currentChat } = useContext(NewChatContext);
  const messages = currentChat.currentChat.messages;

  return (
    <div
      id="chat-body"
      className="xl:p-8 p-5 xl:pb-0 pb-0 h-[100%-160.95px] overflow-auto flex flex-col-reverse"
    >
      <InfiniteScroll
        dataLength={messages.length}
        next={() => fetchChat(false)}
        className="flex flex-col-reverse"
        inverse={true}
        hasMore={information.currentPage !== information.totalPages}
        loader={<Loading />}
        scrollableTarget="chat-body"
      >
        <div>
          {messages?.map((message, key) => (
            <MessageItem
              agent={currentChat?.currentChat?.agent}
              message={message}
              time={{
                previous: messages[key - 1]?.date_sent || message.date_sent,
                current: message.date_sent,
              }}
              showTime={messages[key - 1]?.direction !== message.direction}
              key={key}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

const Footer = () => {
  const { newChat, setNewChat, handleSendButton, files, setFiles } =
    useContext(NewChatContext);
  const [showEmojiBox, setShowEmojiBox] = useState(false);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === '/') {
        event.preventDefault();
        // Making focus on send input field
        document.getElementById('send-input')?.focus();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  // Handling input value change
  const sendInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendButton();
    }
  };

  // Handle emoji change
  const emojiSelect = (emojiObject) => {
    // Extracting emoji
    const emoji = emojiObject.native;
    // Getting current pointer position in text field
    const input = document.getElementById('send-input');
    // selectedArea is going to return current pointer position
    const selectedArea = input.selectionStart || 0;

    // Updating text field value
    setNewChat(
      [newChat.slice(0, selectedArea), emoji, newChat.slice(selectedArea)].join(
        ''
      )
    );
    // Making focus on input field
    input.focus();
    // Hidding emoji box
    setShowEmojiBox(false);
  };

  return (
    <div className="relative mt-auto xl:mx-8 mx-5 sm:py-5 py-2 flex justify-between items-center flex-wrap">
      <input
        type="text"
        id="send-input"
        autoFocus={true}
        value={newChat}
        onChange={(e) => setNewChat(e.target.value)}
        onKeyDown={sendInputKeyDown}
        autoComplete="off"
        className={`${
          !isArabic(newChat) ? 'font-sans' : 'font-noto-sans-arabic'
        } bg-white transition-all duration-100 focus:shadow-lg focus:shadow-black/[0.02] p-4 font-medium text-sm rounded-lg w-full block outline-none sm:pr-48`}
        placeholder={lang('type-reply')}
      />
      <div className="right ml-auto flex items-center flex-wrap sm:absolute static right-2 top-1/2 sm:-translate-y-1/2 sm:mt-0 mt-2 gap-3">
        <input
          className="border-none outline-none cursor-pointer text-gray-600 hidden"
          id="files"
          type="file"
          onChange={(e) => setFiles(e.target.files)}
          multiple={true}
        />
        <label
          htmlFor="files"
          className="cursor-pointer border-none outline-none text-gray-600"
        >
          {files === null || files.length === 0 ? (
            <Attach />
          ) : (
            <div className="font-normal text-gray-700 text-sm text-center">
              <span>{files.length}</span>
              <span className="block text-xs -mt-1">SOR</span>
            </div>
          )}
        </label>

        <button className="border-none outline-none cursor-pointer text-gray-600 relative">
          <span onClick={() => setShowEmojiBox(!showEmojiBox)}>
            <Emoji />
          </span>

          <div
            style={{ display: showEmojiBox ? 'block' : 'none' }}
            className="absolute right-0 bottom-8"
          >
            <Picker data={data} onEmojiSelect={emojiSelect} />
          </div>
        </button>
        <button
          id="send-button"
          className="ml-2 text-sm inline-flex items-center gap-2 bg-primary-dark/[0.9] transition duration-100 hover:bg-primary-dark focus:bg-primary-dark px-4 py-2 rounded-md text-white font-medium"
          onClick={handleSendButton}
        >
          {lang('send')} <Send width={20} height={20} />
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
