// Import icons
import { DirectNormal, Home, Call, User, Logout } from '../assets/icons'
import { lang } from '../language'
// Logout function
import { logout } from './Auth'

// Navlinks
export const NavLinks = [
  {
    text: lang('inbox'),
    href: '/',
    icon: <DirectNormal width={19} />,
    permissions: ['admin', 'agent'],
  },
  {
    text: lang('contacts'),
    href: '/contacts',
    icon: <Call width={19} />,
    permissions: ['admin'],
  },
  {
    text: lang('users'),
    href: '/users',
    icon: <User width={19} />,
    permissions: ['admin'],
  },
  {
    text: lang('logout'),
    href: '/auth/logout',
    icon: <Logout width={19} />,
    onClick: (e) => {
      e.preventDefault()
      logout()
    },
  },
]
