import React from 'react'
import { moment } from '../../hooks/Date'

const DateDivider = ({ date }) => {
  return (
    <div className="chat-divider">
      <span className="text">{moment(date)}</span>
      <span className="line"></span>
    </div>
  )
}

export default DateDivider
