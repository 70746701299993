import React, { useContext, useEffect, useState } from 'react'
import { sendRequest } from '../hooks/API'
import Sidebar from '../components/Inbox/Sidebar'
import ChatBox from '../components/Inbox/ChatBox'
import { InboxContext } from '../contexts/InboxContext'
import Toast from '../hooks/Toast'
import { env } from '../config'
import { wait } from '../hooks/Promise'
import { AuthContext } from '../contexts/AuthContext'
import { useParams } from 'react-router-dom'
// Images
import ChatImage from '../assets/images/inbox-illustration.svg'

const Inbox = () => {
  const { auth } = useContext(AuthContext)
  const { chatId } = useParams()
  const [conversations, setConversations] = useState(null)
  const [chatHistory, setChatHistory] = useState(null)
  const [currentChat, setCurrentChat] = useState(null)
  const [information, setInformation] = useState({
    currentPage: 1,
    nextPage: 2,
    totalPages: 2,
    total: 0,
  })

  useEffect(() => {
    if (auth.id) {
      fetchConversations()
    }
  }, [auth])

  // Fetching conversations and store it to conversation state
  const fetchConversations = async (defaultLoad = true, agent_id = null) => {
    let endpoint = `/chat/conversations`
    // Adding page number if it's not default load
    if (!defaultLoad) {
      endpoint += `?page=${information.nextPage}`
      // Wait for 1s, if it's development mode
      if (env.NODE_ENV === 'development') wait(1000)
    }

    // If it's not an agent, send user id to request
    if (auth.role === 'agent' || agent_id !== null) {
      endpoint += `?agent_id=${agent_id === null ? auth.id : agent_id}`
    }
    if ((!defaultLoad && auth.role === 'agent') || agent_id !== null) {
      endpoint += `&agent_id=${agent_id === null ? auth.id : agent_id}`
    }
    // Fetching conversations
    const response = await sendRequest(endpoint, 'GET')
    // Handling error
    if (response.data.status === 'error') {
      return Toast(response.data.message, 'error')
    }
    const data = response.data.data

    // Updating states
    if (defaultLoad) {
      // For first time load
      setConversations((prev) => ({ ...prev, data }))
    } else {
      const finalData = conversations.data.concat(data)
      setConversations({ data: finalData })
    }
    // If it's not a default load just update the next page (this will help for infinite scrolling)
    setInformation((prev) => ({
      ...prev,
      currentPage: response.data.information.page,
      nextPage:
        response.data.information.page === response.data.information.total_pages
          ? response.data.information.page
          : response.data.information.page + 1,
      totalPages: response.data.information.total_pages,
      total: response.data.information.total_rows,
    }))
  }

  return (
    <div
      className="lg:grid h-full overflow-auto"
      style={{ gridTemplateColumns: '25vw 75vw' }}
    >
      <InboxContext.Provider
        value={{
          conversations,
          chatHistory: { chatHistory, setChatHistory },
          currentChat: { currentChat, setCurrentChat },
          methods: { fetchConversations },
          information,
        }}
      >
        <Sidebar />
        {chatId ? (
          <ChatBox />
        ) : (
          <div className="flex justify-center items-center h-full">
            <img
              src={ChatImage}
              alt="Chat"
              className="w-96 pointer-events-none"
            />
          </div>
        )}
      </InboxContext.Provider>
    </div>
  )
}

export default Inbox
